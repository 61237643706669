const localStorageState = localStorage
export default{
    mutations:{
        setRegistrationNumberStorageState: (state, payload) => {
            localStorageState.setItem('RegistrationNumber', payload)
        },
        setCVRNumberStorageState: (state, payload) => {
            localStorageState.setItem('CVRNumber', payload)
        },
        setRememberMeStorageState: (state, payload) => {
            localStorageState.setItem('RememberMe', payload)
        },
        unsetRegistrationNumberAndCVrNumberStorageState: () => {
            localStorageState.removeItem('RegistrationNumber')
            localStorageState.removeItem('CVRNumber')
        },
        setRegistrationInformationStorageState: (state, payload) => {
            localStorageState.setItem('RegistrationInformation', JSON.stringify(payload))
        },
        unsetRegistrationInformationStorageState: () => {
            localStorageState.removeItem('RegistrationInformation')
        },
        setExemptionReasonsStorageState: (state, payload) => {
            localStorageState.setItem('ExemptionReasons', JSON.stringify(payload))
        },
        unsetExemptionReasonsStorageState: () => {
            localStorageState.removeItem('ExemptionReasons')
        },
        unsetRegistrationNumberStorageState: () => {
            localStorageState.removeItem('RegistrationNumber')
        },
        unsetCVRNumberStorageState: () => {
            localStorageState.removeItem('CVRNumber')
        },
    },
    actions:{
        getRememberMeStorageState: () => {
            return localStorage.getItem('RememberMe') || false
        },
        getRegistrationNumberStorageState: () => {
            return localStorage.getItem('RegistrationNumber') || null   
        },
        getCVRNumberStorageState: () => {
            return localStorage.getItem('CVRNumber') || null
        },
        getRegistrationInformationStorageState: () => {
            return JSON.parse(localStorage.getItem('RegistrationInformation')) || null
        },
        getExemptionReasonsStorageState: () => {
            return JSON.parse(localStorage.getItem('ExemptionReasons')) || null
        }
    }
}