export default class RegistrationInformationModel{
    constructor(data){
        this.id = data.id
        this.registrationNumber = data.registrationNumber
        this.utcRegistrationTime = data.utcRegistrationTime
        this.locationName = data.locationName
        this.base64Image = data.base64Image ? `data:image/Jpeg;base64,${data.base64Image}` : null
        this.base64PlateImage = data.base64PlateImage ? `data:image/Jpeg;base64,${data.base64PlateImage}` : null
        
        this.vehicleInfo = new Object()
        this.vehicleInfo.vehicleType = data.vehicleInfo ? data.vehicleInfo.vehicleType : null
        this.vehicleInfo.brand = data.vehicleInfo ? data.vehicleInfo.brand : null
        this.vehicleInfo.model = data.vehicleInfo ? data.vehicleInfo.model : null
        this.vehicleInfo.plateColor = data.vehicleInfo ? data.vehicleInfo.plateColor : null

        this.customerInfo = new Object()        
        this.customerInfo.name = data.customerInfo.name,
        this.customerInfo.cvrNumber = data.customerInfo.cvrNumber          
    }
}