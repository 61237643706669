import {registrationInformation as apiRegistrationInformation } from '@/api/Requests/RegistrationInformationRequest'
import RegistrationInformationModel from '@/store/Models/RegistrationInformationModel'
import store from '../store'

export default{
    state:{
        registration: null
        
    },
    mutations:{
        setRegistrationInformation: (state, payload) => {
            state.registration = payload
            store.commit('setRegistrationInformationStorageState', state.registration)
        },
        unSetRegistrationInformation: state => {
            state.registration = null    
            store.commit('unsetRegistrationInformationStorageState')
        }
    },     
    actions:{
        FetchRegistrationInformation: async function(context, payload) {
            return apiRegistrationInformation(payload.registrationNumber, payload.cvrNumber).then(response => {
                if(response && response.status === 200){
                    if(response.data.registration){
                        let registration = new RegistrationInformationModel(response.data.registration)
                        context.commit('setRegistrationInformation', registration)
                        return {
                            code: 'OK',
                            searchBufferInMinutes: response.data.searchBufferInMinutes 
                        }
                    }
                    else if(response.data.codes[0] === 'exemptionforrowalreadyexists'){
                        return {
                            code: 'EXIST',
                            searchBufferInMinutes: response.data.searchBufferInMinutes 
                        }
                    }
                    else {
                        return {
                            code: 'NOTFOUND',
                            searchBufferInMinutes: response.data.searchBufferInMinutes
                        }
                    }
                }
                return {
                    code: 'ERROR'
                }
            })
        },
        ClearRegistrationState: context => {
            context.commit('unSetRegistrationInformation')
        },
        async GetRegistrationInformation({dispatch}){
            return dispatch('getRegistrationInformationStorageState')
        }      
    }
}