import Vue from 'vue'
import Vuex from 'vuex'
import LicenseplateCVRDataState from '@/store/GetLicenseplateCVRDataState'
import RegistrationInformationState from '@/store/RegistrationInformationState'
import ExemptionReasonsState from '@/store/ExemptionReasonsState'
import PersistedStorage from '@/store/PersistedStorage'

Vue.use(Vuex)

const getters = {}

const modules = {
    licenseplateCVRDataState: LicenseplateCVRDataState,
    registrationInformationState: RegistrationInformationState,
    exemptionReasonsState: ExemptionReasonsState,
    persistedStorage: PersistedStorage
}

const store = new Vuex.Store({
    strict: true, // todo set to true in production

    state: {
    },
    mutations: {
    },
    actions: {
    },
    getters,
    modules
})

if (module.hot) {
    // accept actions and mutations as hot modules
    module.hot.accept([
        '@/store/GetLicenseplateCVRDataState',
        '@/store/RegistrationInformationState',
        '@/store/ExemptionReasonsState',
        '@/store/PersistedStorage'
    ], () => {
        // require the updated modules
        // have to add .default here due to babel 6 module output
        store.hotUpdate({
            modules: {
                licenseplateCVRDataState: require('@/store/GetLicenseplateCVRDataState').default,
                registrationInformationState: require('@/store/RegistrationInformationState').default,
                exemptionReasonsState: require('@/store/ExemptionReasonsState').default,
                persistedStorage: require('@/store/PersistedStorage').default,
            }
        })
    })
}

Vue.prototype.$store = store

export default store