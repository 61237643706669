import { callApi } from '@/api/apibase'

export const exemptionsReasons = function() {
    
    return callApi('GET', '/api/exemptions/reasons', null)
}

export const exemptionsReasonsTest = async function() {
    return {
        status: 200,
        data: {
            reasons:[
                {
                    id: 3,
                    reason: 'Dummy reason'
                }]
        }
    }    
}