//https://www.npmjs.com/package/vue-axios
import axios from 'axios'
let apiUrl = process.env.VUE_APP_API_URL

export const callApi = function(method, endpoint, body, overriddenHeaders, overriddenBody){
    
    // Set the headers
    let headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }

    if(overriddenHeaders) {
        headers = overriddenHeaders
    }

    // Create the request
    const request = {
        method,
        url: apiUrl + endpoint,
        headers: headers,
        cache: 'no-cache'
    }

    /* // If a login token is present. Put it as a header
    if (localStorage.getItem('token')) {
    // If we have an authorization token. put it in headers
        request.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    } */

    // If a body is present - put it in the request
    if (body) {
        request.data = JSON.stringify(body)
    }

    if(overriddenBody) {
        request.data = overriddenBody
    }

    return axios(request)
        .then(response => {           
            return response
        })
        .catch(() => {
            return null            
        })
}