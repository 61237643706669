import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuetify from 'vuetify'
/* REPLACEMENT FOR MOMENT JS */
/* DOCS: https://www.npmjs.com/package/vue-luxon */
import VueLuxon from 'vue-luxon'
/* INTERNATIONALIZATION */
/* https://kazupon.github.io/vue-i18n/started.html#html */
import VueI18n from 'vue-i18n'
import da from '@/translations/da.json'
import en from '@/translations/en.json'
import se from '@/translations/se.json'
import '@/assets/css/main.css'
// import setupInterceptors from '@/api/setupInterceptors'

Vue.config.productionTip = false

Vue.use(Vuetify)
Vue.use(VueLuxon, {
    templates: {
        formatutc: {
            zone: 'utc',
            format: 'iso'         
        },
        formatlocal: {
            format: 'dd-MM-yyyy HH:mm:ss',
            zone: 'Europe/Copenhagen'            
        }
    },
    input: {
        format: 'iso',
        zone: 'utc'        
    },
    output: { 
        format: 'dd-MM-yyyy HH:mm:ss', 
        zone: 'Europe/Copenhagen'
    }
})
Vue.use(VueI18n)

let formatter = new Intl.NumberFormat('da-DK', {
    style: 'currency',
    currency: 'DKK',
})

Vue.mixin({
    data(){
        return {
            clientName : process.env.VUE_APP_CUSTOMER_NAME,
            clientEmail: process.env.VUE_APP_CUSTOMER_EMAIL,
            clientPhone: process.env.VUE_APP_CUSTOMER_PHONE 
        }
    },
    methods: {
        /*******************************************************************
       * GLOBAL FIELD VALIDATORS
       *******************************************************************/
        globalUsernameValidator: function(value) {
            let errors = []

            errors = errors.concat(this.globalCheckNotEmpty(value))

            errors = errors.concat(this.globalEmailValidator(value))

            return errors
        },
        globalCheckNotEmpty: function(value) {
            let errors = []
            if(!value){
                errors.push(this.$t('error-empty'))
            }
            return errors
        },
        globalRequireDigit: function(value) {   
            let errors = []         
            let digitRegex = /\d/
            if (digitRegex.test(value) === false) {
                errors.push(this.$t('error-missingdigit'))
            }
            return errors
        },
        globalRequireSpecialChar: function(value) {   
            let errors = []         
            let specialCharRegex = /[!@#$%^&]/
            if (specialCharRegex.test(value) === false) {
                errors.push(this.$t('error-missingspecialchar'))
            }
            return errors
        },
        globalCheckIfOnlyLettersAndDigits: function(value) {   
            let errors = []         
            let specialCharRegex = /[^A-Za-z0-9]+/
            if (specialCharRegex.test(value) === true) {
                errors.push(this.$t('error-specialcharincluded'))
            }
            return errors
        },
        globalRequiredLowerCase: function(value) {
            let errors = []   
            let hasOnlyUppercases = value.toUpperCase() === value
            if (hasOnlyUppercases) {
                errors.push(this.$t('error-missinglowercase'))
            }
            return errors
        },
        globalRequiredUpperCase: function(value) {
            let errors = []   
            let hasOnlyLowercases = value.toLowerCase() === value
            if (hasOnlyLowercases) {
                errors.push(this.$t('error-missinguppercase'))
            }
            return errors
        },      
        globalCheckIsNumber: function(value){
            let errors = []

            errors = errors.concat(this.globalCheckNotEmpty(value))

            if(value){
                if(isNaN(value)){
                    errors.push(this.$t('error-notanumber'))
                }
            }            

            return errors
        },
        globalEmailValidator: function(value) {
            let errors = []

            errors = errors.concat(this.globalCheckNotEmpty(value))

            if (value) {
                let emailRegex = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                if (emailRegex.test(value) === false) {
                    errors.push(this.$t('error-emailwrongformat'))
                }
            }
          
            return errors
        },
        globalPhoneNumberValidator: function(value){
            let errors = []

            errors = errors.concat(this.globalCheckNotEmpty(value))

            if (value) {
                let phonenumberRegex = /^\d+$/
                if (phonenumberRegex.test(value) === false) {
                    errors.push(this.$t('error-phonenumber-wrongformat'))
                }
                // Minimum 8 characters
                if (value.length < 8) {
                    errors.push(this.$t('error-phonenumber-minlenght'))
                }
            }
            return errors
        },
        globalCvrNumberValidator: function(value) {
            if(value && value.includes('-'))
                value = value.replace('-', '')
            let errors = []

            errors = errors.concat(this.globalCheckNotEmpty(value))
            errors = errors.concat(this.globalCheckIsNumber(value))

            return errors
        },
        globalRegistrationNumberValidator: function(value) {
            let errors = []

            errors = errors.concat(this.globalCheckNotEmpty(value))
            errors = errors.concat(this.globalCheckIfOnlyLettersAndDigits(value))

            return errors
        },
        /*******************************************************************
       * GLOBAL CURRENCY CONVERTERS
       *******************************************************************/
        globalConvertMinorCurrencyToLocal: function(value) {
            return formatter.format(value/100)
        }
    },
})

// setupInterceptors(store)

new Vue({
    router,
    store,
    vuetify: new Vuetify(),
    i18n: new VueI18n({
        locale: process.env.VUE_APP_I18N_LOCALE, // set locale
        fallbackLocale: 'da', // set fallback locale
        messages: {
            en: en,
            da: da,
            se: se
        } // set locale messages
    }),
    render: h => h(App),
}).$mount('#app')
