import {exemptionsReasons as apiExemptionsReasons } from '@/api/Requests/ExemptionsReasonsRequest'
import ExemptionsReasonsModel from '@/store/Models/ExemptionsReasonsModel'
import {exemptionReasonCommand as apiExemptionReasonCommand } from '@/api/Requests/ExemptionRequestCommand'
import {exemptionBuffer as apiExemptionBuffer} from '@/api/Requests/GetExemptionBufferRequest'
import store from '../store'

export default{
    state:{
        exemptionReasons: []
        
    },
    mutations:{
        setExemptionReasons: (state, payload) => {
            store.commit('setExemptionReasonsStorageState', payload.reasons)
            state.exemptionReasons = payload.reasons   
        },
        unsetExemptionReasons: state => {
            store.commit('unsetExemptionReasonsStorageState')
            state.exemptionReasons = []
        }
    },      
    actions:{
        FetchExemptionReasons: async context => {
            return apiExemptionsReasons().then(response => {
                if(response && response.status === 200 && !!response.data.reasons){
                    let exemptionReasons = new ExemptionsReasonsModel(response.data.reasons)
                    context.commit('setExemptionReasons', exemptionReasons)
                    return true
                }
                return false
            })
 
        },
        GetExemptionBuffer: () => {
            return apiExemptionBuffer().then(response => {
                if(response && response.status === 200 && !!response.data){
                    return response.data
                }
                return null
            })
        },
        SendExemptionRequest: (context, payload) => {
            return apiExemptionReasonCommand(payload.registrationId, payload.exemptionReasonId, payload.phoneNumber, payload.base64Image, payload.exemptionReasonMessage)
        },
        ClearExemptionReasons: context => {
            context.commit('unsetExemptionReasons')
        },
        async GetExemptionReasons({dispatch}){
            return dispatch('getExemptionReasonsStorageState')
        }
    }
}