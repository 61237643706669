import store from '../store'

export default{
    state:{
        registrationNumber: null,
        cvrNumber: null
    },
    mutations:{
        setRegistrationNumber: (state, payload) => {
            if(payload.registrationNumber){
                store.commit('setRegistrationNumberStorageState', payload.registrationNumber)
            }else{
                store.commit('unsetRegistrationNumberStorageState')
            }
            state.registrationNumber = payload.registrationNumber
        },
        setCVRNumber: (state, payload) => {
            if(payload.cvrNumber){
                store.commit('setCVRNumberStorageState', payload.cvrNumber)
            }
            else{
                store.commit('unsetCVRNumberStorageState')
            }    
            state.cvrNumber = payload.cvrNumber       
        },        
        setRememberMe: (state, payload) => {
            store.commit('setRememberMeStorageState', payload.rememberMe)
        },
        unsetRegistrationNumberAndCVrNumber: state => {
            store.commit('unsetRegistrationNumberAndCVrNumberStorageState')
            state.registrationNumber = null
            state.cvrNumber = null
        }
    },   
    actions:{
        SetRegistrationNumber: (context, payload) => {
            context.commit('setRegistrationNumber', payload)
        },
        SetRememberMe: (context, payload) => {
            context.commit('setRememberMe', payload)
        },
        SetCVRNumber: (context, payload) => {
            context.commit('setCVRNumber', payload)
        },
        ClearLicensePlateAndCVRDataState: context => {
            context.commit('unsetRegistrationNumberAndCVrNumber')
        },
        async GetRegistrationNumber({ dispatch }){
            return dispatch('getRegistrationNumberStorageState')
        },
        async GetCVRNumber({ dispatch }){
            return dispatch('getCVRNumberStorageState')
        },
        GetRememberMe({ dispatch }){
            return dispatch('getRememberMeStorageState')
        }
    }
}