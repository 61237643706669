import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
    {
        name:'Index',
        path: '/',
        props: true,
        component: () => import('@/views/pages/Index')
    },
    {
        name: '400',
        path: '/error',
        component: () => import('@/views/pages/Error'),
        props: true,
        meta: {
            title: 'Fejl'
        }
    },
    {
        name: '200',
        path: '/success',
        component: () => import('@/views/pages/Success'),
        meta: {
            title: 'Sucess'
        }
    },
    {
        name: 'ExcemptionRequest',
        path: '/exemptionrequest',
        component: () => import('@/views/pages/ExemptionRequest'),
        meta: {
            title: 'Fritagelsesforespørgsel'
        }
    },
    {
        name: 'RegistrationInformation',
        path: '/registrationinformation',
        component: () => import('@/views/pages/RegistrationInformation'),
        meta: {
            title: 'Indkørselsoplysninger'
        }
    },           
    {
        name: 'Contact',
        path: '/contact',
        component: () => import('@/views/shared/ContactInfo'),
        meta: {
            title: 'Kontakt'
        }
    },
    {
        name: 'Terms',
        path: '/terms',
        component: () => import('@/views/shared/TermsInfo'),
        meta: {
            title: 'Vilkår'
        }
    },
    {
        name: 'Cookies',
        path: '/cookies',
        component: () => import('@/views/shared/CookieInfo'),
        meta: {
            title: 'Cookie- og privatlivspolitik'
        }
    }            
        
     
]
const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
})

export default router

