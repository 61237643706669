import { callApi } from '@/api/apibase'

export const exemptionReasonCommand = function(registrationId, exemptionReasonId, phoneNumber, base64Image, exemptionReasonMessage) {
    let input = {
        RegistrationId: registrationId,
        ExemptionReasonId: exemptionReasonId,
        PhoneNumber: phoneNumber,
        Base64Image: base64Image,
        ExemptionReasonMessage: exemptionReasonMessage
    }
    return callApi('POST', '/api/exemptions/request/add', input)
}