<template>
    <v-app>
        <v-app-bar
            app 
            :color="color"
            height="65"
        >
            <v-img
                style="max-width:150px; max-height: 60px; margin: auto"
                contain
                :src="img"
            />
        </v-app-bar>
        
        <v-main>
            <router-view />
        </v-main>
        <footer-bar />
    </v-app>
</template>

<script>

export default {
    name: 'App',
    components: {
        FooterBar:  () => import('@/views/shared/Footer')
    },
    computed: {
        color(){
            return process.env.VUE_APP_COMPANY_COLOR
        },
        img(){
            return process.env.VUE_APP_COMPANY_LOGO
        }
    }
}
</script>

<style>
#app {
    min-width: 320px;
    width: auto !important;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    /* margin-top: 60px; */
}
</style>
